var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"o-users-list"},[_c('v-divider'),_c('v-card-text',[_c('v-row',[(_vm.hasAccessTo('employee') || _vm.hasManyCompanies())?_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('m-company-filter',{attrs:{"hide-details":"","outlined":"","dense":""},model:{value:(_vm.model.company),callback:function ($$v) {_vm.$set(_vm.model, "company", $$v)},expression:"model.company"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","label":"Szukaj","append-icon":"mdi-magnify"},model:{value:(_vm.model.search),callback:function ($$v) {_vm.$set(_vm.model, "search", $$v)},expression:"model.search"}})],1)],1)],1),_c('v-divider'),(!_vm.state.loaded)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{attrs:{"headers":_vm.state.headers,"items":_vm.state.items,"options":_vm.state.options,"server-items-length":_vm.state.total,"loading":_vm.state.loading,"hide-default-footer":_vm.short,"footer-props":{ itemsPerPageOptions: [10, 25, 50, 100] }},on:{"update:options":function($event){return _vm.$set(_vm.state, "options", $event)}},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
var item = ref.item;
return [(item.email)?_c('a',{staticClass:"text-decoration-none",attrs:{"href":("mailto:" + (item.email))}},[_vm._v(_vm._s(item.email))]):_c('v-divider')]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [(item.phone)?_c('a',{staticClass:"text-decoration-none",attrs:{"href":("tel:" + (encodeURIComponent(item.phone)))}},[_vm._v(_vm._s(item.phone))]):_c('v-divider')]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [(item.role)?_c('span',[_vm._v(_vm._s(_vm.getRoleName(item.role)))]):_c('v-divider')]}},{key:"item.companies",fn:function(ref){
var item = ref.item;
return [(item.companies && item.companies.length)?_c('div',{staticClass:"d-flex flex-wrap my-1"},_vm._l((item.companies),function(company){return _c('v-chip',{key:company.id,staticClass:"mr-2 my-1",attrs:{"small":""}},[_vm._v(_vm._s(company.name))])}),1):_c('v-divider')]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-menu',{attrs:{"left":"","disabled":item.isActive || !_vm.hasAccessTo('admin')},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"depressed":"","fab":"","x-small":"","color":item.isActive ? '' : 'error'}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v(_vm._s(item.isActive ? 'mdi-lock-open-check' : 'mdi-lock-remove'))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.isActive ? 'Konto aktywne' : 'Konto nieaktywne'))])])]}}],null,true)},[_c('v-list',{attrs:{"min-width":"200"}},[_c('v-list-item',{attrs:{"color":"success"},on:{"click":function($event){return _vm.activateUser(item.id)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"green--text"},[_vm._v("Nadaj hasło i aktywuj")])],1),_c('v-list-item-icon',[_c('v-icon',{staticClass:"green--text"},[_vm._v("mdi-lock-open-check")])],1)],1)],1)],1),_c('v-badge',{attrs:{"bordered":"","left":"","color":"red","offset-x":15,"offset-y":15,"value":_vm.hasUnreadFrom(item.id),"content":_vm.getUnreadFrom(item.id)}},[_c('v-btn',{staticClass:"mx-1",attrs:{"depressed":"","fab":"","x-small":"","to":{ name: 'panel.contact.service', params: { id: item.id } }}},[_c('v-icon',[_vm._v("mdi-email")])],1)],1),(_vm.hasAccessTo('admin'))?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"depressed":"","fab":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',{attrs:{"min-width":"200"}},[_c('v-list-item',{attrs:{"to":{ name: 'panel.user.edit', params: { id: item.id } }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Edytuj")])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1),_c('v-list-item',{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteUser(item.id)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"red--text"},[_vm._v("Usuń")])],1),_c('v-list-item-icon',[_c('v-icon',{staticClass:"red--text"},[_vm._v("mdi-delete")])],1)],1)],1)],1):_vm._e()],1)]}},(_vm.short && _vm.state.total > 10)?{key:"footer",fn:function(){return [_c('v-card-text',[_c('v-btn',{staticClass:"mt-4",attrs:{"depressed":"","block":"","to":{ name: 'panel.user.list' },"exact":""}},[_vm._v("Pokaż więcej")])],1)]},proxy:true}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }