
































































import useContact from "@/use/contact";
import useUser from "@/use/user";
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { watchDebounced } from "@vueuse/shared";
import { AxiosInstance } from "axios";
import { DataOptions, DataTableHeader } from "vuetify";

interface RowClickEventData {
  expand: (value: boolean) => void;
  headers: DataTableHeader[];
  isExpanded: boolean;
  isMobile: boolean;
  isSelected: boolean;
  item: any;
  select: (value: boolean) => void;
}

export default defineComponent({
  props: {
    short: {
      type: Boolean,
      required: false,
    },
  },

  components: {
    MCompanyFilter: () => import("@/components/molecules/m-company-filter.vue"),
  },

  setup(props, { root }) {
    const axiosInstance = root.$store.getters[
      "api/getInstance"
    ] as AxiosInstance;
    const { getRoleName, hasAccessTo, hasManyCompanies } = useUser({ root });
    const { fetchUnreadFrom } = useContact({ root });

    const model = reactive({
      search: null as null | string,
      company: null as any,
    });

    const state = reactive({
      headers: computed(() => {
        const headers = [];

        headers.push({ text: "Imię", value: "firstName", sortable: false });
        headers.push({ text: "Nazwisko", value: "lastName", sortable: false });
        headers.push({ text: "Email", value: "email", sortable: false });
        headers.push({ text: "Telefon", value: "phone", sortable: false });
        headers.push({ text: "Rola", value: "role", sortable: false });
        if (
          (hasAccessTo.value("employee") || hasManyCompanies.value()) &&
          !model.company
        )
          headers.push({
            text: "Firma",
            value: "companies",
            sortable: false,
            width: 1,
          });

        headers.push({
          text: "",
          value: "actions",
          sortable: false,
          align: "end",
        });

        return headers;
      }),
      items: [] as any[],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      total: 0,
      loading: false,
      loaded: false,
      contactTimer: null as any,
    });

    const unreadFrom = ref<{ id: string; count: number }[]>([]);
    const visibleIds = computed<string[]>(() =>
      state.items.map((item) => item.id)
    );

    const fetchUnread = async () => {
      for (const id of visibleIds.value) {
        await fetchUnreadFrom(id)
          .then(({ data: { count } }) => {
            const item = unreadFrom.value.find((i) => i.id === id);

            if (item) item.count = count;
            else unreadFrom.value.push({ id, count });
          })
          .catch(console.log);
      }
    };

    const getUnreadFrom = (id: string) => {
      if (
        unreadFrom &&
        unreadFrom.value &&
        unreadFrom.value.find((i) => i.id === id)
      ) {
        return unreadFrom.value.find((i) => i.id === id)?.count as number;
      } else {
        return 0;
      }
    };

    const hasUnreadFrom = (id: string) => {
      return getUnreadFrom(id) > 0;
    };

    const onRowClick = (event: MouseEvent, data: RowClickEventData) => {
      root.$router.push({
        name: "panel.user.view",
        params: { id: data.item.id },
      });
    };

    const fetchData = () => {
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;
      const search = model.search || undefined;
      const filter = {
        company: model.company ? model.company.id : undefined,
      };

      state.loading = true;

      axiosInstance
        .get("user", {
          params: { sortBy, sortDesc, page, itemsPerPage, search, filter },
        })
        .then(({ data: { users, total } }) => {
          state.items = users.map((user: any, index: number) => ({
            id: index,
            firstName: "",
            lastName: "",
            email: "",
            role: "",
            company: null,
            actions: null,
            ...user,
          }));
          state.total = total;
        })
        .catch(() => {
          state.items = [];
          state.total = 0;
          console.log("error");
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    watch(model, () => (state.options.page = 1), { deep: true });
    watchDebounced(() => [model, state.options], fetchData, {
      deep: true,
      debounce: 500,
      maxWait: 5000,
      immediate: true,
    });
    watchDebounced(() => state.options, fetchUnread, {
      deep: true,
      debounce: 500,
      maxWait: 5000,
    });

    onMounted(() => (state.contactTimer = setInterval(fetchUnread, 5000)));
    onBeforeUnmount(() =>
      state.contactTimer ? clearInterval(state.contactTimer) : void 0
    );

    const deleteUser = (id: string) => {
      state.loading = true;

      axiosInstance
        .delete(`user/${id}`)
        .then(fetchData)
        .catch(console.log)
        .finally(() => (state.loading = false));
    };

    const activateUser = (id: string) => {
      state.loading = true;

      axiosInstance
        .put(`user/${id}/set-active`)
        .then(fetchData)
        .catch(console.log)
        .finally(() => (state.loading = false));
    };

    return {
      hasAccessTo,
      hasManyCompanies,
      state,
      model,
      onRowClick,
      getRoleName,
      getUnreadFrom,
      hasUnreadFrom,
      deleteUser,
      activateUser,
      encodeURIComponent,
    };
  },
});
